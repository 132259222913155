import {
  Box,
  Checkbox,
  CircularProgress,
  LinearProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  useReactTable
} from '@tanstack/react-table'

import { ReactComponent as CheckBoxIcon } from '@/assets/icons/box-icon.svg'
import { ReactComponent as CheckboxCheckedIcon } from '@/assets/icons/ic-checkbox-checked.svg'

import ViewAllHistoryButton from '@/components/Button/ViewAllHistoryButton'
import { NoRecentMatches } from '@/components/NoRecentMatches'
import DownloadSelectedRows from '@/components/Tables/DownloadSelectedRows'
import { useVerifyAndReplaceAssets } from '@/pages/asset/hooks'
import { getHasAssetAnyResult } from '@/pages/history/utils'
import { Asset, AssetStatus, ReliabilitiesLabels } from '@/types/assets'
import { normalizeDate } from '@/utils/date'
import { capitalizeEveryWord } from '@/utils/str'
import { useMemo, useState } from 'react'
import RatingButtons from './RatingButtons'
import ReliabilityLabel, { Reliability } from './ReliabilityLabel'
import WrapWithTooltip from '@/components/Tables/WrapWithTooltip'

type HistoryItem = {
  company: string
  date: string
  fullName: string
  like: boolean | undefined
  providedEmail: string
  replacementEmail: string
  verification: Reliability
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.quaternary,
  fill: theme.palette.text.quaternary,
  fontSize: '12px',
  fontWeight: 600,
  textTransform: 'uppercase'
}))

const StyledTableRow = styled(TableRow)({
  '& td:first-child': {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 10
  },
  '& td:last-child': {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10
  }
})

const columnHelper = createColumnHelper<Asset>()

const HEADER_CELL_ID = 4

const HistoryTable = () => {
  const assetsData = useVerifyAndReplaceAssets()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: () => (
          <DownloadSelectedRows rowSelection={rowSelection} event="Single VF - Download Results" />
        ),
        cell: ({ row }) => {
          const asset = row.original

          return (
            <Checkbox
              icon={<CheckBoxIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              disabled={getHasAssetAnyResult(asset) === false}
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          )
        }
      }),
      columnHelper.accessor('singleEnrichment.fullName', {
        id: 'fullName',
        header: 'Full Name',
        cell: (cell) => (
          <WrapWithTooltip limit={10}>{capitalizeEveryWord(cell.getValue())}</WrapWithTooltip>
        )
      }),
      columnHelper.accessor('singleEnrichment.company', {
        id: 'company',
        header: 'Company',
        cell: (cell) => <WrapWithTooltip limit={10}>{cell.getValue()}</WrapWithTooltip>
      }),
      columnHelper.accessor('singleEnrichment.workEmail', {
        id: 'providedEmail',
        header: 'Provided Email',
        cell: (cell) => <WrapWithTooltip limit={10}>{cell.getValue()}</WrapWithTooltip>
      }),
      columnHelper.accessor('singleEnrichment.verificationLabel', {
        id: 'verificationLabel',
        header: 'Verification',
        cell: (cell) => {
          const label = cell.getValue()
          const relaiability = ReliabilitiesLabels[label]
          return <ReliabilityLabel label={label} reliability={relaiability} />
        }
      }),
      columnHelper.accessor('singleEnrichment.enrichedWorkEmail', {
        id: 'replacementEmail',
        header: 'Replacement Email',
        cell: (cell) => {
          const { enrichedWorkEmail, verificationLabel } = cell.row.original.singleEnrichment
          if (verificationLabel === 'Accurate') return

          return <WrapWithTooltip limit={10}>{enrichedWorkEmail}</WrapWithTooltip>
        }
      }),
      columnHelper.accessor('rating', {
        id: 'rating',
        header: 'Rating',
        cell: (cell) => <RatingButtons asset={cell.row.original} assets={assetsData} />
      }),
      columnHelper.accessor('createdAt', {
        id: 'date',
        header: 'Date',
        cell: (cell) => normalizeDate(cell.getValue())
      })
    ],
    [rowSelection]
  )

  const { data, isLoading } = assetsData
  const { assets = [] } = data ?? {}

  const table = useReactTable({
    columns,
    data: assets,
    getCoreRowModel: getCoreRowModel<HistoryItem>(),
    enableRowSelection: true,
    getRowId: (row) => {
      return row._id
    },
    onRowSelectionChange: (e) => {
      setRowSelection(e)
    },
    state: {
      rowSelection
    }
  })

  if (isLoading)
    return (
      <Box className="flex justify-center">
        <CircularProgress />
      </Box>
    )

  if (assets.length === 0) return <NoRecentMatches />

  const headerGroups = table.getHeaderGroups()
  const { headers } = headerGroups[0]

  return (
    <>
      <TableContainer className="history-list p-0">
        <Table>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <StyledTableCell key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <StyledTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const isProcessing = (index: number) =>
                    cell.column.id === headers[index].id &&
                    row.original.status === AssetStatus.PROCESSING

                  if (isProcessing(HEADER_CELL_ID))
                    return (
                      <TableCell key={cell.id} colSpan={2}>
                        <LinearProgress className="rounded" />
                      </TableCell>
                    )
                  else if (isProcessing(HEADER_CELL_ID + 1)) return null
                  else
                    return (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    )
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ViewAllHistoryButton href="/history" />
    </>
  )
}

export default HistoryTable
